import React, { Component } from "react";
import { connect } from "react-redux";
import { SliderProMsgActions } from "../actions";
import Slider from "react-slick";
import "./HomePage.css";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: window.location.origin,
    };
  }

  componentDidMount() {
    this.props.fetchSliderApi();
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      height: "100%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div>
        <div className="wrapper">
          {/* Top Bar Start */}
          <div className="top-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-12">
                  <div className="logo">
                    <a href="index.html">
                      <img src="img/logo.gif" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7">
                  <div className="row">
                    <div className="col-4">
                      <div className="top-bar-item">
                        <div className="top-bar-icon">
                          <i class="fa fa-whatsapp" aria-hidden="true"></i>
                        </div>
                        <div className="top-bar-text">
                          <h3>WhatsApp</h3>
                          <p>+91-9811145620</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="top-bar-item">
                        <div className="top-bar-icon">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div className="top-bar-text">
                          <h3>Call</h3>
                          <p>+91-9811145620</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="top-bar-item">
                        <div className="top-bar-icon">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="top-bar-text">
                          <h3>Email</h3>
                          <p>hytechearth@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Top Bar End */}
          {/* Nav Bar Start */}
          <div className="nav-bar">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                <a href="#" className="navbar-brand">
                  MENU
                </a>
                <button type="button" className=" btn-outline-hp ">
                  {" "}
                  <a href={`${this.state.origin}/Login`} target="_blank">
                    {" "}
                    Login Now
                  </a>
                </button>
                <button
                  type="button"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbarCollapse"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between"
                  id="navbarCollapse"
                >
                  <div className="navbar-nav mr-auto">
                    <a href="#index" className="nav-item nav-link active">
                      Home
                    </a>
                    <a href="#about" className="nav-item nav-link">
                      About us
                    </a>
                    <a href="#product" className="nav-item nav-link">
                      Exclusive Range Products
                    </a>
                    <a href="#contact" className="scroll nav-item nav-link">
                      Contact us
                    </a>
                  </div>
                  <div className="ml-auto">
                    <a
                      className="btn"
                      href={`${this.state.origin}/Login`}
                      target="_blank"
                    >
                      Login Now
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <Slider {...settings}>
            {this.props.data.map((item, i) => (
              <div>
                {item.FileType.includes("image") ? (
                  <img height="710px" width="100%" src={item.FilePath} />
                ) : null}
                {item.FileType.includes("video") ? (
                  <video
                    controls
                    autoplay
                    height="710px"
                    width="100%"
                    src={item.FilePath}
                  />
                ) : null}
              </div>
            ))}
          </Slider>
          <div className="feature wow fadeInUp" data-wow-delay="0.1s">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-12">
                  <div className="feature-item">
                    <div className="feature-icon">
                      <i className="flaticon-worker" />
                    </div>
                    <div className="feature-text">
                      <h3>Expert Worker</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="feature-item">
                    <div className="feature-icon">
                      <i className="flaticon-building" />
                    </div>
                    <div className="feature-text">
                      <h3>Quality Products</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="feature-item">
                    <div className="feature-icon">
                      <i className="flaticon-call" />
                    </div>
                    <div className="feature-text">
                      <h3>
                        24/7
                        <br />
                        Support
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Feature End*/}
          <section
            id="about"
            className="ftco-section ftc-no-pb default-padding"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5">
                  <div className="about-img">
                    <img
                      src="img/about.jpg"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <h2 className=" pl-md-5 mb-3 heading_about">About Us</h2>
                  <div className="pl-md-5">
                    <p className="para_about">
                      With over 30 years of experience in the earthmoving
                      industry, <b>HYTECH PARTS</b> has established itself as
                      the only trusted leader in the aftermarket supply of
                      OEM-quality parts. We specialize in a comprehensive range
                      of components for JCB backhoe loaders and excavators,
                      designed to meet the rigorous demands of earthmoving
                      machinery. <br />
                      Our extensive inventory includes everything you need to
                      keep your equipment running at peak performance: from
                      Transmission & Axle Parts, Ground Engaging Tools (G.E.T),
                      and Undercarriage Parts to Hydraulic Parts, Fabricated
                      Components, Electrical Parts, Hoses, Bearings, Pivot Pins,
                      Bushings, Control Cables, Filters, and more.
                      <br />
                      We are committed to providing exceptional quality and
                      value, ensuring that our customers receive durable,
                      reliable parts that support their operations seamlessly.
                    </p>
                  </div>
                  <section className="text-center about">
                    <div className="container">
                      <div className="row">
                        <div
                          className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn"
                          data-wow-offset="200"
                        >
                          <span className="fa fa-line-chart"></span>
                          <h2 className="heading_about my-5">Our Mission</h2>
                          <p className="lead">
                            <ul>
                              <li>
                                To establish a strong global presence by
                                delivering premium-quality products that put our
                                customers at the center of our HYTECH family.
                              </li>
                              <li>
                                To continuously enhance our supply chain for
                                maximum efficiency, effectiveness, and
                                responsiveness.
                              </li>
                              <li>
                                To motivate and reward our dedicated workforce,
                                empowering them to strive toward our goal of
                                zero-defect products.
                              </li>
                            </ul>
                          </p>
                        </div>

                        <div
                          className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn"
                          data-wow-offset="200"
                        >
                          <span className="fa fa-eye"></span>
                          <h2 className="heading_about my-5">Our Vision</h2>
                          <p className="lead">
                            <ul>
                              <li>
                                Delivering impeccable, zero-defect products with
                                a commitment to on-time delivery.
                              </li>
                              <li>
                                Optimizing our supply chain to support our
                                mission of quality and reliability.
                              </li>
                              <li>
                                Achieving excellence through sincerity,
                                consistency, and continuous improvement, with
                                customer feedback as a guiding force.
                              </li>
                            </ul>
                          </p>
                        </div>
                        <div className="clearfix visible-md-block visible-sm-block"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>

          {/* Fact Start */}
          <div className="fact">
            <div className="container-fluid">
              <div className="row counters">
                <div className="col-md-6 fact-left wow slideInLeft">
                  <div className="row">
                    <div className="col-6">
                      <div className="fact-icon">
                        <i className="flaticon-worker" />
                      </div>
                      <div className="fact-text">
                        <h2 data-toggle="counter-up">20000+</h2>
                        <p>Satisfied customers</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fact-icon">
                        <i className="flaticon-call" />
                      </div>
                      <div className="fact-text">
                        <h2 data-toggle="counter-up">24/7</h2>
                        <p>Hours support</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 fact-right wow slideInRight">
                  <div className="row">
                    <div className="col-6">
                      <div className="fact-icon">
                        <i className="flaticon-crane" />
                      </div>
                      <div className="fact-text">
                        <h2 data-toggle="counter-up">100000</h2>
                        <p>Products</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fact-icon">
                        <i className="flaticon-worker" />
                      </div>
                      <div className="fact-text">
                        <h2 data-toggle="counter-up">20+</h2>
                        <p>Professional agents</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fact End */}

          {/* Team Start */}
          <div id="product" className="team default-padding">
            <div className="container-fluid">
              <div className="section-header text-center">
                <p>FEATURED PRODUCTS</p>
                <h2>Our Exclusive Range Products</h2>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-1.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-2.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-3.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-4.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-5.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-6.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-7.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item">
                    <div className="team-img">
                      <img src="img/properties-8.jpg" alt="Product Image" />
                    </div>
                    <div className="team-text">
                      <h2>Hytech Products</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team End */}

          {/* Portfolio Start */}
          <div className="portfolio">
            <div className="container-fluid">
              <div className="section-header text-center">
                <p>Our Events</p>
                <h2>News & Events</h2>
              </div>

              <div className="row portfolio-container">
                <div
                  className="col-lg-3 col-md-6 col-sm-12 portfolio-item first wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="portfolio-warp">
                    <div className="portfolio-img">
                      <img src="img/e17-1.jpg" alt="Image" />
                      <div className="portfolio-overlay">
                        <p>EXCON 2017 </p>
                      </div>
                    </div>
                    <div className="portfolio-text">
                      <h3>EXCON 2017</h3>
                      <a
                        className="btn"
                        href="img/e17-1.jpg"
                        data-lightbox="portfolio"
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-12 portfolio-item second wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="portfolio-warp">
                    <div className="portfolio-img">
                      <img src="img/e15-1.jpg" alt="Image" />
                      <div className="portfolio-overlay">
                        <p>EXCON 2015 </p>
                      </div>
                    </div>
                    <div className="portfolio-text">
                      <h3>EXCON 2015</h3>
                      <a
                        className="btn"
                        href="img/e15-1.jpg"
                        data-lightbox="portfolio"
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="portfolio-warp">
                    <div className="portfolio-img">
                      <img src="img/e13-1.jpg" alt="Image" />
                      <div className="portfolio-overlay">
                        <p>EXCON 2013 </p>
                      </div>
                    </div>
                    <div className="portfolio-text">
                      <h3>EXCON 2013</h3>
                      <a
                        className="btn"
                        href="img/e13-1.jpg"
                        data-lightbox="portfolio"
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-12 portfolio-item first wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="portfolio-warp">
                    <div className="portfolio-img">
                      <img src="img/bauma-1.jpg" alt="Image" />
                      <div className="portfolio-overlay">
                        <p>BAUMA GERMANY </p>
                      </div>
                    </div>
                    <div className="portfolio-text">
                      <h3>BAUMA GERMANY</h3>
                      <a
                        className="btn"
                        href="img/bauma-1.jpg"
                        data-lightbox="portfolio"
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 load-more">
                  <a className="btn" href="https://hytechparts.net/events/">
                    More Events
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Portfolio End */}
          {/* FAQs Start */}
          <div className="faqs">
            <div className="container-fluid">
              <div className="section-header text-center">
                <p>Special quality for</p>
                <h2>Genuine Parts</h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div id="accordion-1">
                    <div className="card wow fadeInLeft" data-wow-delay="0.1s">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-toggle="collapse"
                          href="#collapseOne"
                        >
                          Top Most manufacturer of Eathmoving - Original,
                          Replacment, OEM and Genuine Parts.
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-parent="#accordion-1"
                      >
                        <div className="card-body">
                          We are the leading Manufacturer, Supplier & Exporter
                          of Backhoe loader & Excavator Machinery parts for
                          aftermarket. We have complete range of Transmission &
                          Axle Parts, G.E.T parts, Undercarriage parts,
                          Hydraulic Parts, Hydraulic Seal Kits, Hydralic Hoses,
                          Fabricating Parts, Electrical Parts, Hoses, Bearing,
                          Pivot Pin, Bushes, Control cables & Filters etc. We
                          offer premium quality replacement parts for JCB,
                          HITACHI, KOMATSU and CASE. We develop, anufacture &
                          supply one of the best and premium quality heavy
                          earthmoving machinery spares parts using the state of
                          the art manufacturing procedure.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FAQs End */}

          {/* Contact Start */}
          <div className="container-fluid">
            <div id="contact" className="contact wow fadeInUp default-padding">
              <div className="section-header text-center">
                <p>Get In Touch</p>
                <h2>For Any Query</h2>
              </div>
              <div className="row query-form">
                <div className="col-md-6">
                  <div className="contact-info">
                    <div className="contact-item">
                      <i className="flaticon-address" />
                      <div className="contact-text">
                        <h2>Location</h2>
                        <p>
                          Plot no. 39, Main Mathura Rd, Near, Badkhal Flyover,
                          Sector 27/A, Faridabad, Haryana 121003
                        </p>
                      </div>
                    </div>
                    <div className="contact-item">
                      <i className="flaticon-call" />
                      <div className="contact-text">
                        <h2>Phone</h2>
                        <p>+91-129-4047216 / 4085620</p>
                        <p>+91 98111 45620</p>
                      </div>
                    </div>
                    <div className="contact-item">
                      <i className="flaticon-send-mail" />
                      <div className="contact-text">
                        <h2>Email</h2>
                        <p> hytechparts.sales@gmail.com</p>
                        <p> hytechearth@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-form">
                    <div id="success" />
                    <form
                      name="sentMessage"
                      id="contactForm"
                      noValidate="novalidate"
                    >
                      <div className="control-group">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required="required"
                          data-validation-required-message="Please enter your name"
                        />
                        <p className="help-block text-danger" />
                      </div>
                      <div className="control-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          required="required"
                          data-validation-required-message="Please enter your email"
                        />
                        <p className="help-block text-danger" />
                      </div>

                      <div className="control-group">
                        <textarea
                          className="form-control"
                          id="message"
                          placeholder="Message"
                          required="required"
                          data-validation-required-message="Please enter your message"
                          defaultValue={""}
                        />
                        <p className="help-block text-danger" />
                      </div>
                      <div>
                        <button
                          className="btn"
                          type="submit"
                          id="sendMessageButton"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Contact End */}
          {/* Start Google Maps 
    ============================================= */}

          <div className="container">
            <div className="row">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.6983098620112!2d77.30478451463874!3d28.4283587824988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd8c40000001%3A0x2263d943881465bd!2sHYTECH%20EARTH%20MOVING%20ENGINEERS!5e0!3m2!1sen!2sin!4v1568121818221!5m2!1sen!2sin"
                width="100%"
                height={350}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen
                data-ytogwy-handled={1}
              />
            </div>
          </div>

          {/* End Google Maps */}

          {/* Footer Start */}
          <div className="footermy wow fadeIn" data-wow-delay="0.3s">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="footermy-contact">
                    <h2>Office Contact</h2>
                    <p>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                      Plot no. 39, Main Mathura Rd, Near, Badkhal Flyover,{" "}
                      <br />
                      Sector 27/A, Faridabad, Haryana 121003
                    </p>
                    <p>
                      <i className="fa fa-mobile" aria-hidden="true"></i>{" "}
                      +91-9811145620
                    </p>
                    <p>
                      <i className="fa fa-phone-square" aria-hidden="true"></i>{" "}
                      +91-129-4047216 / 4085620
                    </p>
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                      hytechearth@gmail.com
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="footermy-link">
                    <h2>Useful Pages</h2>
                    <a href="#index">Home</a>
                    <a href="#about">About Us</a>
                    <a href="#product">Exclusive Range Products</a>
                    <a href="#contact">Contact Us</a>
                  </div>
                </div>
                <div className="col-md- col-lg-3 boxxe">
                  <h2>Follow us by</h2>
                  <a
                    href="https://www.facebook.com/Hytech-parts-113633513375285/"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="container footermy-menu">
              <div className="f-menu"></div>
            </div>
            <div className="container copyright">
              <div className="row">
                <div style={{ width: "60%" }}>
                  <p style={{ marginBottom: "0px" }} className="Disclaimer">
                    <b>Disclaimer: </b>
                    O.E Part numbers are just reference purpose only and we do
                    not imply that these parts are the products of any OEM
                  </p>
                </div>
                <div style={{ width: "40%" }}>
                  <p style={{ marginBottom: "0px", textAlign: "end" }}>
                    Copyright 2023 @ Hytech Earth Moving Engineers. | All Rights
                    Reserved
                  </p>
                </div>
                {/* <div className="col-md-6 text-right designedby">Design & Developed by <a target="_blank" href="https://www.holostik.com/">  Holostik India Limited</a></div> */}
              </div>
            </div>
          </div>
          {/* Footer End */}
          <a href="#" className="back-to-top">
            <i className="fa fa-chevron-up" />
          </a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data:
      state.SliderProMsg.item == (null || undefined)
        ? []
        : state.SliderProMsg.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSliderApi: () => {
      dispatch(SliderProMsgActions.GetSliderImage());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
