import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="m-5">
        <h2 className="text-center">
          Hytech Earth Moving Engineers APPLICATION - PRIVACY POLICY
        </h2>
        At Hytech Earth Moving Engineers, we appreciate that you are trusting us
        with information that is important to you, and we want to be transparent
        about how we use it. Here we describe the privacy practices for our
        applications, software and services (the “Services”). You will learn
        about the data we collect, how we use it, and the measures we take to
        keep it safe.
        <h4 className="mt-3">INFORMATION WE COLLECT</h4>
        <p>
          When you use our Services, we collect the following types of
          information.
        </p>
        <h4 className="mt-3">ACCOUNT INFORMATION</h4>
        <p>
          Some information is required to create an account on our Services,
          such as your name, email address, password and your mobile telephone
          number. You may also choose to provide other types of information,
          such as a profile photo, date of birth &amp; gender.
        </p>
        <p>
          If you contact us or participate in a survey, contest, or promotion,
          Hytech Earth Moving Engineers collects the information you submit such
          as your name, contact information, and message.
        </p>
        <h2 className="mt-3">
          INFORMATION WE RECEIVE FROM YOUR USE OF OUR SERVICES
        </h2>
        <h4 className="mt-3">LOCATION INFORMATION</h4>
        <p>
          The Services include features that use precise location data,
          including GPS signals, device sensors, Wi-Fi access points, and cell
          tower IDs. We collect this type of data if you grant us access to your
          location while installing the application. We may also derive your
          approximate location from your IP address.
        </p>
        <h2 className="mt-3">HOW WE USE INFORMATION</h2>
        <p>We use the information we collect for the following purposes,</p>
        <h4 className="mt-3">PROVIDE AND MAINTAIN THE SERVICES</h4>
        <p>
          Using the information we collect, we are able to deliver the Services
          to you and honor our Terms of Service contract with you. For example,
          we need to use your information to provide you with the paperless
          warranty feature, and to give you customer support.
        </p>
        <h4 className="mt-3">IMPROVE, PERSONALISE AND DEVELOP THE SERVICES</h4>
        <p>
          We use the information we collect to improve and personalize the
          Services and to develop new ones. For example, we use the information
          to troubleshoot and protect against errors; perform data analysis and
          testing; conduct research, and develop new features and Services.
        </p>
        <p>
          When you allow us to collect precise location information, we use that
          information to provide and improve features of the Services such as
          recording where the product was scanned.
        </p>
        <h4 className="mt-3">COMMUNICATE WITH YOU</h4>
        <p>
          We use your information when needed to send you Service notifications
          and respond to you when you contact us. We may also use your
          information to promote new features or products that we think you
          would be interested in.
        </p>
        <h4 className="mt-3">PROMOTE SAFETY AND SECURITY</h4>
        <p>
          We use the information we collect to promote the safety and security
          of the Services, our users, and other parties. For example, we may use
          the information to authenticate users, protect against fraud and
          abuse, respond to a legal request or claim, conduct audits, and
          enforce our terms and policies.
        </p>
        <p>
          For personal data, we rely on several legal bases to process the data.
          These include when you have given your consent, which you may withdraw
          at any time using your account settings and other tools; when the
          processing is necessary to perform a contract with you, like the Terms
          of Service; and our legitimate business interests, such as in
          improving, personalising, and developing the Services, marketing new
          features or products that may be of interest, and promoting safety and
          security as described above.
        </p>
        <h2 className="mt-3">HOW INFORMATION IS SHARED</h2>
        <p>
          We do not share your personal information except in the limited
          circumstances described below.
        </p>
        <h4 className="mt-3">FOR LEGAL REASONS OR TO PREVENT HARM</h4>
        <p>
          We may preserve or disclose information about you to comply with a
          law, regulation, legal process, or governmental request; to assert
          legal rights or defend against legal claims; or to prevent, detect, or
          investigate illegal activity, fraud, abuse, violations of our terms,
          or threats to the security of the Services or the physical safety of
          any person.
        </p>
        <p>
          Please note: Our policy is to notify you of legal process seeking
          access to your information, such as search warrants, court orders, or
          subpoenas unless we are prohibited by law from doing so. In cases
          where a court order specifies a non-disclosure period, we provide
          delayed notice after the expiration of the non-disclosure period.
          Exceptions to our notice policy include exigent or counterproductive
          circumstances, for example, when there is an emergency involving a
          danger of death or serious physical injury to a person.
        </p>
        <p>
          If we are involved in a merger, acquisition, or sale of assets, we
          will continue to take measures to protect the confidentiality of
          personal information and give affected users notice before
          transferring any personal information to a new entity.
        </p>
        <h2 className="mt-3">DATA RETENTION</h2>
        <p>
          We keep your account information, like your name, email address, and
          password, for as long as your account is in existence because we need
          it to operate your account. We also keep information about you and
          your use of the Services for as long as necessary for our legitimate
          business interests, for legal reasons, and to prevent harm.
        </p>
        <h2 className="mt-3">
          ANALYTICS AND ADVERTISING SERVICES PROVIDED BY OTHERS
        </h2>
        <p>
          We might work with partners who provide us with analytics and
          advertising services. This may include helping us understand how users
          interact with the Services, serving advertisements on our behalf
          across the internet, and measuring the performance of those
          advertisements. These companies may use cookies and similar
          technologies to collect information about your interactions with the
          Services and other websites and applications.
        </p>
        <h2 className="mt-3">INFORMATION SECURITY</h2>
        <p>
          We work hard to keep your data safe. We use a combination of
          technical, administrative, and physical controls to maintain the
          security of your data. This includes using Transport Layer Security
          (“TLS”) to encrypt many of our Services. No method of transmitting or
          storing data is completely secure, however.
        </p>
        <h2 className="mt-3">CHANGES TO THIS POLICY</h2>
        <p>
          We will notify you before we make material changes to this policy and
          give you an opportunity to review the revised policy before deciding
          if you would like to continue to use the Services.
        </p>
        <h2 className="mt-3">WHO WE ARE AND HOW TO CONTACT US</h2>
        <p>
          If you have questions, suggestions, or concerns about this policy, or
          about our use of your information, please contact us on Customer Care
          No: +91 9811145620.
        </p>
      </div>
    );
  }
}
